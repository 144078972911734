<template>
  <div>
    <div v-for="(video, index) in videos" :key="index" class="mb-3" :id="video.id">
      <h2>{{ video.title }}</h2>
      <p>{{ video.description }}</p>
      <div class="row">
        <div class="col col-md-5">
          <b-embed type="iframe" aspect="16by9" :src="video.embedUrl" frameborder="0" allowfullscreen class="video-help"></b-embed>
        </div>
      </div>
      <hr>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videos: [
        {
          id:'promos',
          title: 'Promociones',
          description: '',
          embedUrl: 'https://www.youtube.com/embed/CqEak7DUrOI'
        },
        {
          id:'inventario',
          title: 'Administrar inventario',
          description: '',
          embedUrl: 'https://www.youtube.com/embed/qUBGwbjJMhw'
        },
        {
          id:'agregarTienda',
          title: 'Agrega tu primer tienda',
          description: '',
          embedUrl: 'https://www.youtube.com/embed/hDpi8ROwUXI'
        },
        {
          id:'editarEstablecimiento',
          title: 'Edita tu tiendita o sucursal',
          description: '',
          embedUrl: 'https://www.youtube.com/embed/Y2Jkg4Lj2lk'
        },
        {
          id:'adminInventario',
          title: 'Administrar inventario',
          description: '',
          embedUrl: 'https://www.youtube.com/embed/cxKqmfeJtDA'
        },
        {
          id:'editarInventario',
          title: 'Editar inventario',
          description: '',
          embedUrl: 'https://www.youtube.com/embed/m_8hKc6XAaw'
        },
        {
          id:'primerVenta',
          title: 'Realiza tu primer venta',
          description: '',
          embedUrl: 'https://www.youtube.com/embed/dyitBFVY_2w'
        },
        {
          id:'devolucion',
          title: 'Devuelve un producto',
          description: '',
          embedUrl: 'https://www.youtube.com/embed/bDBKGJhFTtU'
        },
        {
          id:'inventariar',
          title: 'Realizar inventario',
          description: '',
          embedUrl: 'https://www.youtube.com/embed/EXx3yWFx6IU'
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>
